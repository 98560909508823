@use '../../../../styles/abstracts' as *;
.services {
    justify-content: center;
    @include grid;
    margin: 1em 0;
    .classesHeading, .servicesHeading {
        @include grid-col(1, 2);
        @include mq(medium) {
            @include grid-col(1, 3);
            grid-row: initial;
            margin: 1em 0 0 0;
        }
        @include mq(large) {
            @include grid-col(1, 5);
        }
    }
    .servicesHeading {
        grid-row: 2;
        margin: 3.44rem 0;
        text-transform: uppercase;
        @include mq(medium) {
            grid-row: initial;
            margin: 1em 0 0 0;
        }
        @include mq(large) {
            margin: 0;
            grid-row: initial;
            h2, h3 {
                margin-bottom: 1em;
            }
        }
        
    }
    .classesHeading {
        @include grid-col(1, 3);
        @include mq(large) {
            margin-top: 7em;
        }
        @include mq(medium) {
            @include grid-col(1, 4);
            margin-top: 4em;
        }
    }
    .image {
        .service {
            grid-row: 1;
        }
        @include mq(large) {
            margin-bottom: 9.62rem;
        }
    
        img {
            max-width: 100%;
            margin-left: 3em;
            @include mq(large) {
                margin-left: 9.3em;
            }
            @include mq(medium) {
                margin-left: 4em;
            }
        }
    }
    .classesDescription, .image {
        @include grid-col(1, 4);
        
        @include mq(medium) {
            @include grid-col(4, 5);
        }
        @include mq(large) {
            @include grid-col(6, 8);
        }
    }
    .classesDescription {
        h3 {
            margin: 2em 0 1em 0;
            text-transform: uppercase;
            border-bottom: to_rem(1) solid $color-primary-black;
            width: fit-content;
        }
        @include mq(medium) {
            @include grid-col(6, 5);
            p {
                margin: 2em 0
            }
        }
        @include mq(large) {
            @include grid-col(7, 5);
            p {
                margin: 3em 0;
            }
        }
    }

    .locationImageFull {
        margin-top: 4em;
        img {
            object-fit: cover;
            margin-left: 0;
            min-width: 97vw;
            transform: translate(-4em);
            @include mq(medium) {
                min-width: initial;
                margin-right: 4em;
                min-height: to_rem(174);
                max-height: to_rem(175);
            }
            @include mq(large) {
                margin-right: 9.3em;
                min-height: to_rem(318);
                max-height: to_rem(351);
            }
        }
        
        @include grid-col(1, 5);
        @include mq(medium) {
            @include grid-col(1, 4);
            grid-row: initial;
            margin: 4em 0 0 0;
        }
        @include mq(large) {
            @include grid-col(1, 5);
            margin-top: 9.62em;
        }
    
    }
    .splitImageOne {
        grid-row: 2;
        @include grid-col(1, 2);
        @include mq(medium) {
            margin: 3em 0 0 0;
            @include grid-col(5, 1);
            grid-row: initial;
        }
        @include mq(large) {
            @include grid-col(7, 2);
            margin-top: 8.81em;
        }
        img {
            margin-top: 1em;
            object-fit: cover;
            min-height: to_rem(175);
            width: 100%;
            @include mq(medium) {
                margin-right: 4em;
                min-height: to_rem(174);
                max-height: to_rem(175);
            }
            @include mq(large) {
                min-height: to_rem(318);
                max-height: to_rem(351);
            }
        }
    }
    .splitImageTwo {
        grid-row: 2;
        @include grid-col(3, 2);
        @include mq(medium) {
            margin: 3em 0 0 0;
            @include grid-col(7, 1);
            grid-row: initial;
        }
        @include mq(large) {
            @include grid-col(10, 2);
            margin-top: 8.81em;
        }
        img {
            margin-top: 1em;
            object-fit: cover;
            min-height: to_rem(175);
            width: 100%;
            @include mq(medium) {
                margin-right: 4em;
                min-height: to_rem(174);
                max-height: to_rem(288);
            }
            @include mq(large) {
                min-height: to_rem(318);
                max-height: to_rem(351);
            }
        }
    
    }
    .textSpan {
        @include grid-col(1, 4);
        margin: 1em 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        h2 {
            text-transform: uppercase;
            margin-bottom: 2em;
        }
        @include mq(medium){
            h2{
                margin: 0;
            }
            margin: 0;
        }
        @include mq(large){
            @include grid-col(1, 5);
        }
    }
    .textColumn, .textColumn2 {
        div  {
            margin-right: 1em;

            @include mq(medium) {
                min-height: 8.3125rem;
            }
        }
        @include grid-col(1, 4);
        display: flex;
        justify-content: space-between;
        margin: 1em 0;
        @include mq(medium) {
            margin: 0;
            display: initial;
        }
        h4 {
            margin-bottom: 1em;
        }
    }
    .textColumn {
        @include mq(medium) {
            @include grid-col(6, 1);
        }
        @include mq(large){
            @include grid-col(8, 2);
        }
    }
    .textColumn2 {
        @include mq(medium) {
            @include grid-col(8, 1);
        }
        @include mq(large){
            @include grid-col(11, 2);
        }
    }
    
}


.locationsText {
    margin-top: 1em;
    @include mq(medium) {
        margin-top: 2em;
    }
    @include mq(large) {
        margin-top: 4em;
    }
}
