@use '../abstracts' as *;

body {
    font-family: $font-roboto;
    overflow-x: hidden;
}

.wrapper {
    padding: 0 1.875rem;
    max-width: 90rem;
    margin: 0 auto;

    @include mq(medium) {
        padding: 0 3.125rem;
    }

    @include mq(large) {
        padding: 0 4.375rem; 
    }
}

button {
    transition: all 300ms ease;
    cursor: pointer;
}

.active {
    transition: all 300ms ease;
    &:hover, 
    &:active {
        background: $color-secondary-purple;
    }
}