@use '../../styles/abstracts' as *;

.about {
    @include grid;
    margin-block: 3.44rem 0;
    max-width: 90rem;
    margin-inline: auto;

    @include mq(large) {
        margin-block: 6.63rem;
    }

    section {
        @include grid-col(1, 4);
        @include grid;

                 
        &:nth-of-type(3) {

            .image {
                justify-self: flex-end
            }
        }

        @include mq(medium) {
            @include grid-col(1, 8);

            .content, .image {
                grid-row: 1/ span 1;
            }
        }

        @include mq(large) {
            @include grid-col(1, 12);

            &:nth-of-type(1) {
                h2 {
                    width: 78%;
                }
            }
    
            &:nth-of-type(2) {
                .content {
                    @include grid-col(8, 5);
                    padding-left: 0;
                    margin-top: 3rem;
                }

                .image {
                    @include grid-col(1, 6);
                }
            }

                    
            &:nth-of-type(3) {
                .image {
                    margin-top: -11rem;
                }
            }

            &:nth-of-type(4) {
                .content {
                    @include grid-col(7, 6);
                    padding-left: 0
                }

                .image {
                    @include grid-col(1, 6);
                    margin-top: -11rem;
                }
            }

            &:nth-of-type(5) {
                .content {
                    @include grid-col(1, 5);
                    padding-right: 0;
                }

                .image {
                    @include grid-col(8, 5);
                    padding-left: 0;
                }
            }
        }

    }

}

.content {
    max-width: 90rem;
    @include grid-col(1, 4);

    @include mq(large) {

        @include grid-col(1, 6);

    }
}

.description {
    margin-block: 2rem 2.31rem;

    a {
        width: 9.8125rem;
        height: 2.125rem;
        margin-bottom: 3.63rem;
    }

    @include mq(large) {
        margin-block: 3.96rem;

        a {
            margin-top: 2.75rem;
 
        }
    }
}

.image {
    @include grid-col(1, 4);
    margin-bottom: 3.44rem;
    
    @include mq(medium) {
        @include grid-col(5, 4);
    }
    @include mq(large) {
        @include grid-col(8, 5);
        margin-bottom: 9.62rem;
    }

    img {
        max-width: 100%;
    }
}

.imageContainer {
    @include grid;

    img {
        height: 100%;
        width: 100%;

        @include mq(large) {
            max-height: 28.625rem;
        }
    }

    picture:first-child {
        @include grid-col(1, 3);

        @include mq(medium) {
            @include grid-col(1, 5);
        }

        @include mq(large) {
            @include grid-col(1, 8);
        }
    }

    picture {
        @include grid-col(4, 1);

        @include mq(medium) {
            @include grid-col(6, 3);
        }
        
        @include mq(large) {
            @include grid-col(9, 5);
        }
    }
}

.inputField, 
.location a {
    padding-bottom: 0.56rem;
    border-bottom: to_rem(1.2) solid $color-primary-black;

    @include mq(large) {
        border-bottom: to_rem(2) solid $color-primary-black;
    }
}

.inputField {
    @include flex(center, flex-start);
    margin-bottom: 1.38rem;

    @include mq(large) {
        margin-bottom: 2.06rem;
    }

    input {
        width: 100%;
        padding: 0;
        padding-bottom: 0;
        padding-left: 0.44rem;
        border: 0;
        outline: none;
        

        @include mq(large) {
            padding-left: 0.88rem;
            font-size: 1.375rem;
        }

        &::placeholder {
            color: #646464;
            font-family: $font-lato;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 300;
            line-height: normal;

            @include mq(large) {
                font-size: 1.375rem;
            }
        }
    }
}

.location {

    a {
        @include flex(center, space-between);
        margin-bottom: 1.19rem;
        text-decoration: none;
        color: $color-primary-black;

        p {
            font-family: $font-lato;
            font-size: 0.8125rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            text-transform: capitalize;

            @include mq(large) {
                font-size: 1.375rem;
            }
        }
    }
}

.findGym {
    @include mq(large) {
        margin-left: -4.375rem;
    }
}