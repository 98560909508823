@use '../../styles/abstracts' as *;

.account {
    color: $color-primary-black;
    
    label {
        font-family: $font-lato;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        text-transform: uppercase;
    }

    input {
        width: 100%;
        padding: 0;
        outline: none;
        border: none;
        border-bottom: 1.2px solid transparent;
        background: none;
        color: #535353;
        font-family: $font-lato;
        font-size: 0.75rem;
        font-weight: 400;
        line-height: 2.375rem; /* 316.667% */
        margin-bottom: 0.5rem;

        &::placeholder {
            font-family: $font-lato;
            font-size: 0.875rem;
            color: $color-placeholder-grey;
            font-weight: 200;
        }
    }

    .edited {
        border-bottom: 1.2px solid $color-primary-black;
    }

    .bookingHistory {
        @include flex(center, space-between);
        margin-block: 3.33rem;
        width: 100%;
        padding-bottom: 1.15rem;
        border-bottom: to_rem(2) solid $color-primary-black;

        h3 {
            text-transform: uppercase;
            font-size: 0.875rem;
        }
    }

    .buttonContainer {
        width: 100%;
        text-align: right;
    }

    .button {
        width: 40%;
    }
}

.button {
    background: $color-primary-black;
    color: $color-primary-white;
    font-family: $font-lato;
    width: 100%;
    outline: none;
    border: none;
    border-radius: 1.25rem;
    padding: 0.75rem 0.625rem;

    @include mq(large) {
        margin-top: 1rem;
        p {
            font-family: $font-lato;
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

    }

    &:hover, 
    &:active {
        opacity: 0.6499999761581421;
    }
}


.expandable {
    @include grid-col(1, 4);
    @include grid;
    @include mq(medium) {
        @include grid-col(1, 8);
    }
    @include mq(large){
        @include grid-col(2, 11)
    }
    cursor: pointer;
}
.expandableTop {
    @include flex(center, space-between);
    @include grid-col(1, 4);
    @include mq(medium) {
        @include grid-col(1, 8);
        div:hover {

            color: $color-secondary-purple;
        }
        @include mq(large){
            @include grid-col(1, 12)
        }
    }
    margin-top: 1em;
    padding-bottom: 1em;
    border-bottom: to_rem(2) solid $color-primary-black;
    div {
        font-size: 1.5rem;
    }
    h4 {
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
    }
}

.hidden {
    visibility: hidden;
    opacity: 0;
    height: 0;
    margin: 0;
    padding: 0;
    p {
        margin-bottom: 0;
    }
}

.dropdown {
    @include grid-col(1, 4);
    @include mq(medium) {
        @include grid-col(1, 8);
    }
    @include mq(large){
        @include grid-col(1, 12)
    }
    margin: 1em 1em 1em 1em;
    ul {
       @include flex(center, flex-start, column);
       width: 100%;
       max-height: 10em;
       overflow: hidden;
       overflow-y: scroll;
       flex-wrap: nowrap;
       li {
        @include flex(center, space-between);
        max-width: 95%;
        width: 100%;
        border-bottom: to_rem(2) solid $color-primary-black;
        padding: 1em;
        h3, h4 {
            padding: 1em;
        }

       }
       li:first-child {
            padding: 0 1em 1em 1em;
        }
        li:last-child {
            border-bottom: none;
        }
        span {
            font-weight: 700;
        }
    }
}