@use '../../styles/abstracts' as *;


header {
    position: fixed;
    z-index: 999999;
    min-height: fit-content;
    height: 4em;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: top 300ms ease;
    @include mq(medium) {
        height: 5.125rem;
        display: static;
    }
    width: 100vw;
    top: 0;
    background: rgba(0, 0, 0, 0.648);
    backdrop-filter: blur(8px);
    .hamburgerMenu {
        color: $color-primary-white;
        margin-right: 1.1em;
        z-index: 999999;
        @include mq(medium) {
            display: none;
        }
    }
    .header {
        margin: 0 auto;
        font-family: $font-lato;
        width: 100vw;
        min-height: fit-content;
        max-width: 90rem;
        
        .loginIcon {
            color: $color-primary-white;
        }
        
        .linkWrapper{
            display: flex;
            justify-content: space-between;
            align-items: center;
            max-height: 5.6em;
            ul:last-child a svg{
                @include mq(medium) {
                    margin-left: 1.2em;
                }

            }
            ul:last-child a span {
                padding-left: 0.2em;
            }
            ul {
                min-height: calc(100vh - 4em);
                top: calc(100vh + 1em);
                background: $color-primary-black;
                width: 100vw;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                position: fixed;
                z-index: 100;
                transition: top 0.5s ease-in-out;
                @include mq(medium) {
                    min-height: 4em;
                    max-width: fit-content;
                    flex-direction: row;
                    position: static;
                    margin-right: 2.55em;
                    background: none;
                    transition: none;
                    overflow: hidden;
                }
            }
            ul li {
                margin: 1em 0;
                @include mq(medium) {
                    margin: 0;
                }

                .active {
                    color: #FFF;
                    font-style: italic;
                }
                
            }
            li a {
                font-size: to_rem(16);
                @include mq(medium) {
                    margin: 0 1.9vw;
                }
            }
            li a:hover, li a:hover svg, .hamburgerMenu:hover {
                color: $color-secondary-purple;
                cursor: pointer;
            }
            ul li a, .headerLogo {
                text-decoration: none;
                color: $color-primary-white;
                font-weight: 300;
            }
            .headerLogo{
                margin: 1.1em 1.8em;
                text-decoration: none;
                text-transform: uppercase;
                font-size: 0.98em;
                letter-spacing: to_rem(2);
                z-index: 999999;
                @include mq(medium) {
                    font-size: 1.51em;
                    margin: 0 2.5em;
                }
            } 
        }   

        .loginSignUpContainer{
            display: flex;
            align-items: center;
            justify-content: center;
            
        }
        
    }

}

