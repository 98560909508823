@use './variables' as *;
@use './breakpoints' as *;

/*
    Usage
==================

   @include mq(medium) {
        @include grid;
        @include grid-col(1, 12);
    }
*/

@mixin grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    column-gap: 1rem;

    @include mq(medium) {
        grid-template-columns: repeat(8, 1fr);
        column-gap: 1.125rem;
    }

    @include mq(large) {
        grid-template-columns: repeat(12, 1fr);
        column-gap: 1.25rem;
    }
}

@mixin grid-col($colStart, $colSpan) {
    grid-column: $colStart / span $colSpan;
}


/*
    Usage
==================

    .header {
        @include flex(center, center);
    }
*/
@mixin flex($alignItem, $justifyContent, $direction: false) {
    display: flex;
    align-items: $alignItem;
    justify-content: $justifyContent;

    @if $direction {
        flex-direction: $direction;
    }
}

    // reference the use of banner mixin by looing at other pages
@mixin banner {
    position: absolute;
    width: 100%;
    overflow: hidden;
    height: 42vh;


    &::before {
        content: '';
        position: absolute;
        background: rgba($color: $color-primary-black, $alpha: 0.8);
        width: 100%;
        height: 100%;
    }
    
    img {
        height: 42vh;
        width: 100%;
        position: relative;
        z-index: -1;

        @include mq(large) {
            height: 100vh;
        }
    }
 

    @include mq(large) {
        height: 100vh;

        img {
            object-fit: cover;
        }
    }
}

@mixin list-item {
    margin-left: 0.5rem;
    list-style: disc;
    padding-left: 0.2em;

    &::marker {
        font-size: 0.5rem;
    }
}

