@use '../../styles/abstracts' as *;

.container {
    @include grid;
    grid-template-rows: 42vh;
    height: 100%;
    color: $color-primary-white;

    @include mq(large) {
        grid-template-rows: repeat(5, 20vh);
    }
}

.heading {
    @include grid-col(1, 3);
    align-self: center;
    z-index: 1;

    @include mq(large) {
        grid-area: 2 / 2 / span 2 / span 3;
        align-self: self-start;
    }

    h1 {
        font-size: 2rem;
        text-transform: capitalize;
        margin-bottom: 0.5rem;

        @include mq(xsmall) {
            font-size: 2.5rem;
        }

        @include mq(large) {
            font-size: 4.375rem;
            margin-bottom: 2.75rem;
        }
    }

    p {
        font-family: $font-lato;
        font-size: 0.90625rem;
        font-weight: 300;
        line-height: 131.034%;

        @include mq(large) {
            font-size: 1.1875rem;
            font-style: normal;
            line-height: 136.842%
        }
    }
}

.formContainer {
    color: $color-primary-black;
    background: $color-primary-white;
    @include grid-col(1, 4);
    z-index: 1;
    max-width: 40rem;
    display: flex;
    flex-direction: column;
    padding: 2.95rem 0 2.87rem;

    @include mq(medium) {
        @include grid-col(2, 6);
    }

    @include mq(large) {
        grid-area: 2 / 6 / span 3 / span 6;
        align-self: flex-start;
        padding: 3.75rem 5.88rem 3.75rem;
    }
    
    h2 {
        margin-bottom: 2.94rem;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 500;
        text-transform: capitalize;
    }
}

.divider {
    width: 100%;
    display: flex;
    flex-direction: row;
    border: none;
    margin-block: 0.5375rem;

    &::before,
    &::after {
        content: "";
        border-bottom: 1px solid #D9D9D9;
        flex: 1 0 auto;
        height: .8em;
        margin: 0;
    }

    span {
        font-family: $font-lato;
        font-size: 0.8125rem;
        font-style: normal;
        font-weight: 300;
        line-height: 1.59375rem; /* 196.154% */
        text-align: center;
        color: #8B8B8B;
        flex: 0.2 0 auto;
        margin: 0;  
    }
}

.termsOfUse {
    @include grid-col(1, 4);
    padding: 0 2rem;
    color: #535353;
    text-align: center;
    font-family: $font-roboto;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 300;
    line-height: 140%;
    margin-top: 1.38rem;

    @include mq(large) {
        padding: 0 4rem;
        margin-top: 2.62rem;
    }

    span {
        text-decoration: underline;
    }
}