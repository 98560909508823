@use '../abstracts/' as *;

    /*
        they haven't firmly decided on font size yet 
        use these for now and lets update later on 
        and also they dont consider stuff related to tablet so we should adjust it while developing
    */

h1, .h1 {
    font-family: $font-lato;
    font-size: 1.25rem;
    font-weight: 400;
    font-style: normal;
    line-height: 130%;
    text-transform: uppercase;

        // tablet
    @include mq(medium) {
        font-size: 2rem;
    }

    @include mq(large) {
        font-size: 3rem;
        line-height: normal;
    }
}

h2, .h2 {
    font-family: $font-lato;
    font-size: 1.1875rem;
    text-transform: uppercase;

    @include mq(medium) {
        font-size: 1.55rem;
    }

    @include mq(large) {
        font-size: 2.5rem;
    }
}

h3, .h3 {
    font-family: $font-lato;
    font-size: 1rem;

    @include mq(large) {
        font-size: 1.75rem;
    }
}

h4, .h4 {
    font-family: $font-lato;
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;

    @include mq(large) {
        font-size: 1.5625rem;
        line-height: 152%;
    }
}

p, .p {
    font-family: $font-roboto;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.25rem; /* 142.857% */

    @include mq(large) {
        font-size: 1.09375rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.65625rem; /* 151.429% */
    }
}