@use '../../styles/abstracts' as *;

.banner {
    background: $color-primary-black;
    color: $color-primary-white;
    height: 75vh;
    overflow: hidden;
    @include flex(flex-end, center);

    @include mq(large) {
        padding-bottom: 5.6rem;
    }

}  

.heading {
    padding-inline: 1.875rem;
    text-align: center;
    @include flex(center, flex-start, column);
    height: 55%;

    @include mq(medium) {
        max-width: 38.9375rem;
        justify-content: flex-start;
    }

    h1 {
        margin-bottom: 1.12rem;

        @include mq(large) {
            margin-bottom: 2.5rem;  
        }
    }

    p {
        font-size: 0.875rem;
        font-weight: 300;
        line-height: 142.857%;
        padding-inline: 0.5rem;

        @include mq(medium) {
            font-size: 1rem;
        }
    }

    a {
        margin-block: 3.51rem;
        @include flex(center, center);
        color: $color-primary-white;
        text-decoration: none;

        @include mq(large) {
            margin-block: 17% 27%;
        }

        svg {
            margin-left: 0.38rem;
        }
    }
}