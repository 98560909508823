@use '../../../../styles/abstracts' as *;

.banner {
    background: $color-primary-black;
    color: $color-primary-white;
    height: 100vh;
    padding-bottom: 10vh;
    overflow-x: hidden;
    @include flex(center, center);

    @include mq(large) {
        padding-bottom: 5.6rem;
    }
    //padding-top: 4rem;
}

.heading {
    padding-top: 10vh;
    padding-inline: 1.875rem;
    @include flex(flex-start, flex-start, column);
    height: 55%;
    width: 45vw;
    max-width: to_rem(500);

    @include mq(medium) {
        max-width: 38.9375rem;
    }
    // @include mq(large) {
    //     padding-inline: 27.38rem;
    // }

    h1 {
        margin-bottom: 1.12rem;

        @include mq(large) {
            margin-bottom: 2.5rem;  
        }
    }
    h4 {
        margin: 1em 0;
        text-transform: uppercase;
    }
    a {
        background: $color-primary-white;
        color: $color-primary-black;
        margin: 4em auto;
        min-height: 2em;
        @include mq(large){
            min-height: 2em;
        }
    }

}

select {
    border: none;
    outline: none;
    background: none;
    width: 100%;
    color: $color-primary-white;
    border-bottom: to_rem(3) solid $color-primary-white;
    padding-bottom: 1em;
    margin: 4em 0 2em 0 ;

}



