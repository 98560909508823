@use '../../styles/abstracts' as *;

.form {
    @include flex(flex-start, flex-start, column);

    input {
        width: 100%;
        padding: 0;
        outline: none;
        border: none;
        border-bottom: 1.2px solid $color-primary-black;
        background: none;
        padding-bottom: 0.44rem;

        &:not(:last-of-type) {
            margin-bottom: 1.69rem;
        }

        &::placeholder {
            font-family: $font-lato;
            font-size: 0.875rem;
            color: $color-placeholder-grey;
            font-weight: 200;
        }
    }

    button {
        margin-top: 2.88rem;

        @include mq(large) {
            margin-top: 2rem;
        }
    }
}

.error {
    @include flex(center, space-between);
    width: 100%;
    font-size: 0.75rem;
    margin-top: 0.5rem;

    P {
        font-size: 0.75rem;
        color: $color-red;
    }

    .wrongPassword {
        @include flex(center, flex-start);

        div {
            @include flex(center, center);
            color: $color-primary-white;
            background: $color-red;
            width: to_rem(15);
            height: to_rem(15);
            border-radius: 50%;
            font-size: 0.5625rem;
            margin-right: 0.5rem;
        }
    }

    .forgotPassword {
        color: #535353;
        font-family: $font-lato;
        text-decoration: underline;
    }
}