@use '../../styles/abstracts' as *;

.banner {
    height: 100vh;
    background: url('../../assets/images/banner.jpg') no-repeat center / cover;
    position: relative;
    @include flex(center, center);
    z-index: 1;
    color: $color-primary-white;

    &::before {
        content: '';
        position: absolute;
        background: rgba($color: $color-primary-black, $alpha: 0.5);
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .scroll {
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        @include flex(center, center, column);

        p {
            text-align: center;
            font-family: $font-roboto;
            font-size: 1.09375rem;
            font-weight: 300;
            line-height: 1.65625rem; /* 151.429% */
            text-transform: uppercase;
            margin-bottom: 0.5rem;
        }

        svg {
            animation: arrowAnim 3s infinite ;
         
        }
    }

    @keyframes arrowAnim  {
        0% {
            transform: translateY(-10px);
            opacity: 0.5;
        }

        50% {
            transform: translateY(0px);
            opacity: 1;
        }

        100% {
            transform: translateY(-10px);
            opacity: 0.5;
        }
    }
    .heading {
        z-index: 1;

        h1 {
            text-align: center;
            font-size: 2.875rem;
            font-weight: 500;
            text-transform: capitalize;
            margin-bottom: 1.62rem;

            @include mq(large) {
                font-size: 5.625rem;
                margin-bottom: 3.13rem;
            }
        }
    
        ul {
            @include flex(center, center);
            margin-bottom: 7.44rem;

            @include mq(large) {
                margin-bottom: 4.37rem;
            }
    
            li {
                text-transform: uppercase;
                position: relative;
                font-family: $font-roboto;
                font-size: 0.875rem;
                font-weight: 300;

                @include mq(large) {
                    font-size: 1.25rem;
                }
    
                &:not(:last-child) {
                    padding-right: 2.25rem;

                    @include mq(large) {
                        padding-right: 5rem;
                    }
                }
    
                &:not(:last-child)::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    width: to_rem(6);
                    height: to_rem(6);
                    border-radius: 50%;
                    background: #D9D9D9;
                    top: 50%;
                    right: 20%;
                    transform: translateY(-50%);

                    @include mq(large) {
                        width: to_rem(11.72);
                        height: to_rem(11.72);
                        right: 25%;
                    }
                }
            }
        }
    
        a {
            margin-inline: auto;
            background: $color-primary-white;
            border: 1px solid $color-primary-black;
            color: $color-primary-black;
            width: 9.8125rem;
            height: 2.125rem;
            transition: all 300ms ease;

            &:hover, 
            &:active {
                background: $color-secondary-purple;
                color: $color-primary-white;
                border: 1px solid $color-secondary-purple;
            }

            @include mq(large) {
                width: 12.8125rem;
                height: 2.625rem;
            }
        }
    }
}

.home {
    @include grid;
    margin-block: 3.44rem 0;
    max-width: 90rem;
    margin-inline: auto;

    @include mq(large) {
        margin-block: 6.63rem;
    }

    section {
        @include grid-col(1, 4);
        @include grid;

                 
        &:nth-of-type(3) {

            .image {
                justify-self: flex-end
            }
        }

        @include mq(medium) {
            @include grid-col(1, 8);

            .content, .image {
                grid-row: 1/ span 1;
            }

            &:nth-of-type(1) {
                .image {
                   margin-top: 4.94rem;
                }
                
            }

            &:nth-of-type(2) {
                .content {
                    align-self: center;
                }
            }

            &:nth-of-type(3) {
                .content {
                    align-self: center;
                }
            }
        }

        @include mq(large) {
            @include grid-col(1, 12);

            &:nth-of-type(1) {
                .image {
                    @include grid-col(6, 7);
                   margin-top: 4.94rem;
                   margin-left: 1rem;
                }
                .content {
                    @include grid-col(1, 5);
                }

                h2 {
                    width: 90%;
                }
                
            }
    
            &:nth-of-type(2) {
                .content {
                    @include grid-col(7, 5);
                    align-self: center;
                    text-align: right;
                    margin-bottom: to_rem(135);
                    padding: 0;
                    padding-left: 3rem;

                    a {
                        margin-left: auto;
                    }
                }

                .image {
                    @include grid-col(1, 6);
                }
            }

                    
            &:nth-of-type(3) {

                .content {
                    @include grid-col(1, 5);
                    align-self: center;
                    margin-top: -5rem;
                    padding-right: 1.5rem;

                    p {
                        margin-bottom: 7.56rem
                    }

                    h2 {
                        width: 75%;
                    }
                }

                .image {
                    @include grid-col(7, 6);
                }
            }
        }
    }
}

.content {
    max-width: 90rem;
    @include grid-col(1, 4);

    @include mq(large) {

        @include grid-col(1, 6);
    }

    .heading {

        span {
            color: #A1A1A1;
            font-family: $font-roboto;
            font-size: 0.875rem;
            font-weight: 300;
            line-height: 1.25rem; /* 142.857% */
            text-transform: uppercase;
            margin-bottom: 0.19rem;
        }
    }
}

.description {
    margin-block: 2rem 2.31rem;


    &:last-child {
        p {
            margin-bottom: 2.56rem;

        }
    }

    a {
        width: 9.8125rem;
        height: 2.125rem;
    }

    @include mq(large) {
        margin-block: 3.96rem;

        a {
            margin-top: 2.75rem;
        }
    }
}

.image {
    @include grid-col(1, 4);
    margin-bottom: 3.44rem;
    overflow: hidden;
    
    @include mq(medium) {
        @include grid-col(5, 4);
    }
    @include mq(large) {
        margin-bottom: 9.62rem;
    }

    img {
        max-width: 100%;
    }
}

.imageContainer {
    @include grid;

    picture:first-child {
        @include grid-col(1, 4);
        margin-bottom: 1.19rem;

        @include mq(medium) {
            @include grid-col(1, 8);
        }

        @include mq(large) {
            @include grid-col(1, 12);
            margin-right: -2rem;
        }
    }

    picture {
        @include grid-col(1, 4);

        @include mq(medium) {
            @include grid-col(1, 8);
        }
        
        @include mq(large) {
            @include grid-col(1, 12);
            margin-right: -2rem;
        }
    }
}

.contact {
    color: $color-primary-black;
    text-decoration: none;
    padding-top: 2.56rem;

    @include mq(large) {
        font-size: 1.5625rem;
    }
}