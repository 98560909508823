@use 'sass:map';

$breakpoints: (
    xsmall: 375px,
    medium: 768px,
    large: 1040px   
);

@mixin mq($key) {
    $size: map.get($breakpoints, $key);

    @media screen and (min-width: $size) {
        @content;
    }
}
